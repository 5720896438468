<template>
  <b-card>
    <!-- form -->
    <b-form>

      <b-row>
        <!-- new password -->
        <b-col md="6">
          <b-form-group label-for="account-new-password" label="Change Password">
            <b-input-group class="input-group-merge">
              <b-form-input id="account-new-password" v-model="newPasswordValue" :type="passwordFieldTypeNew"
                name="new-password" placeholder="New Password" />
              <b-input-group-append is-text>
                <feather-icon :icon="passwordToggleIconNew" class="cursor-pointer" @click="togglePasswordNew" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <!--/ new password -->

        <!-- buttons -->
        <b-col cols="12">
          <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mt-1 mr-1" @click="EditPassword()">
            Save changes
          </b-button>
          <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="reset" variant="outline-secondary" class="mt-1">
            Reset
          </b-button>
        </b-col>
        <!--/ buttons -->
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from 'axios'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      url: localStorage.getItem('baseapi'),
      passwordValueOld: '',
      newPasswordValue: '',
      RetypePassword: '',
      passwordFieldTypeOld: 'password',
      passwordFieldTypeNew: 'password',
      passwordFieldTypeRetype: 'password',
    }
  },
  computed: {
    passwordToggleIconOld() {
      return this.passwordFieldTypeOld === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconNew() {
      return this.passwordFieldTypeNew === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    passwordToggleIconRetype() {
      return this.passwordFieldTypeRetype === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    togglePasswordOld() {
      this.passwordFieldTypeOld = this.passwordFieldTypeOld === 'password' ? 'text' : 'password'
    },
    togglePasswordNew() {
      this.passwordFieldTypeNew = this.passwordFieldTypeNew === 'password' ? 'text' : 'password'
    },
    togglePasswordRetype() {
      this.passwordFieldTypeRetype = this.passwordFieldTypeRetype === 'password' ? 'text' : 'password'
    },
    async EditPassword() {

      axios.put(this.url + 'user/' + router.currentRoute.params.id,
          {
            password: this.newPasswordValue,
          },
          {
            headers: {
              'xth': this.userData.token
            }
          }).then(response => {
            if (response.status == 200) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Success`,
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Successfully changed password'
                },
              })

              this.resetForm()
            } else {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: `Error`,
                  icon: 'CoffeeIcon',
                  variant: 'danger',
                  text: 'Failed to change password',
                },
              })
            }
          })

    },
    resetForm() {
      this.passwordValueOld = ''
      this.newPasswordValue = ''
      this.RetypePassword = ''
    }
  },
}
</script>
